import {Controller} from "@hotwired/stimulus"

// Copy the innerHTML of the first thing with data-target="source" on it.
// Use data-clipboard-target="button" to
export default class extends Controller {
  #originalButtonContent

  static targets = ['source', 'button']
  static values = {
    successContent:  {type: String, default: 'Copied!' }, /* Always i18n, though */
    successDuration: {type: Number, default: 1500},
  }

  connect() {
    if (!this.hasButtonTarget) return

    this.#originalButtonContent = this.buttonTarget.innerHTML
  }

  get copyableContent() {
    return this.sourceTarget.innerText
  }

  copy(event) {
    event.preventDefault()

    if(navigator.clipboard) {
      navigator.clipboard.writeText(this.copyableContent).then(() => this.copied())
    } else {
      console.log('No clipboard available. Are you running over HTTPS?') // eslint-disable-line no-console
    }
  }

  copied() {
    if (!this.hasButtonTarget) return

    this.buttonTarget.innerHTML = this.successContentValue
    this.buttonTarget.disabled = true

    setTimeout(() => {
      this.buttonTarget.disabled = false
      this.buttonTarget.innerHTML = this.#originalButtonContent
    }, this.successDurationValue)
  }
}
