import Base from './base'

export default class extends Base {
  // Mappings from source address provider functions to rails-like attr method names
  ATTRIBUTE_MAPPINGS = {
    'line1':      'line_1',
    'line2':      'line_2',
    'townOrCity': 'town_or_city',
    'county':     'county',
    'postcode':   'postcode',
    'country':    'country'
  }

  static targets = ['autocompleteRoot', 'visibleSearching', 'visibleManual']
  static values  = {
    noResults: String,
    searching: String,
    debounceMs: { type: Number, default: 200 },
    country: { type: String, default: 'UK' }
  }

  get firstInputElement() {
    return this.element.querySelector('#membership_application_address_line_1')
  }

  set completedAddress(address) {
    this.clearErrorMessages()
    // Normally happens after this when setting searching to false, but must happen now to avoid field confusion setting line_1
    this.searching = false
    this.copyAddressValuesToFields(address)
  }

  // Determines whether the search form or the result form fields should be visible when loaded
  shouldStartInSearchMode() {
    return this.allFieldsEmpty() && this.noErrorMessages()
  }

  // Multi-field-specific methods follow here
  allFieldsEmpty() {
    const empty = (input) => input.value.trim().length === 0
    return Array.from(this.element.querySelectorAll('input')).every(input => empty(input))
  }

  noErrorMessages() {
    return !this.element.querySelector('.govuk-error-message')
  }

  clearErrorMessages() {
    Array.from(this.element.querySelectorAll('.govuk-error-message')).forEach(message => message.style.display = 'none')
  }

  copyAddressValuesToFields(address) {
    for(let source in this.ATTRIBUTE_MAPPINGS) {
      const attr = this.ATTRIBUTE_MAPPINGS[source]
      const id = `#membership_application_address_${attr}`
      this.setInputValue(id, address[source])
    }
  }

  setInputValue(id, value) {
    const input = this.element.querySelector(id)
    if(input === null) return

    input.value = value
  }
}
