import {Controller} from "@hotwired/stimulus"
import {show} from "../../govuk/visibility.js"

// When a country radio is changed, update the country on the address-autocomplete outlet.
export default class extends Controller {
  #countryRadios
  static outlets = ['address-autocomplete']

  connect() {
    show(this.element)
  }

  addressAutocompleteOutletConnected() {
    // Set up the change events on each radio
    this.countryRadios.forEach((radio) => radio.addEventListener('change', (e) => {
      this.countryRadioChanged(e.currentTarget)
    }))
    // Set the initial value explicitly on the autocomplete if a radio box is already checked
    if(this.selectedValue) this.addressAutocompleteOutlet.countryValue = this.selectedValue
  }

  get selectedValue() {
    return this.element.querySelector('input[type=radio][checked]')?.value
  }

  get countryRadios() {
    if(this.#countryRadios === undefined) {
      this.#countryRadios = this.element.querySelectorAll('input[type=radio]')
    }
    return this.#countryRadios
  }

  countryRadioChanged(radio) {
    this.addressAutocompleteOutlet.countryValue = radio.value
  }
}
