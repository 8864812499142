import accessibleAutocomplete from 'accessible-autocomplete'

import {Controller} from "@hotwired/stimulus"

// Used to enhance a <select>
export default class extends Controller {
  #autocompleteInput

  static targets = ['select']

  // configure and attach the autocomplete to the designated input.
  connect() {
    accessibleAutocomplete.enhanceSelectElement({
      selectElement: this.selectTarget,
      showAllValues: true,
      autoselect: true,
      confirmOnBlur: true,
      defaultValue: ''
    })
    this.autoCompleteInput.addEventListener('input', () => this.clearSelectValue())
  }

  clearSelectValue() {
    this.selectTarget.value = ''
  }

  get autoCompleteInput() {
    if(this.#autocompleteInput === undefined) {
      this.#autocompleteInput = this.element.querySelector('.autocomplete__wrapper input')
    }
    return this.#autocompleteInput
  }
}
