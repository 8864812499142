const GOVUK_DISPLAY_NONE =  'govuk-!-display-none'

function showHide(element, show) {
  if(show) {
    element.classList.remove(GOVUK_DISPLAY_NONE)
  } else {
    element.classList.add(GOVUK_DISPLAY_NONE)
  }
}

function show(element) {
  showHide(element, true)
}

function hide(element) {
  showHide(element, false)
}

export { show, hide, showHide }
