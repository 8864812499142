import {Controller} from "@hotwired/stimulus"
import {hide} from "../govuk/visibility"

// When the buttonTarget is clicked, shares the urlTarget.innerHTML to the
// url: portion of the navigator.share API data with text: taken from the textValue
export default class extends Controller {
  static targets = ['button', 'url']
  static values = {
    text: String /* Always i18n */
  }

  connect() {
    if(!navigator.share) {
      hide(this.buttonTarget)
    }
  }

  share(event) {
    event.preventDefault()

    if(!navigator.share) return

    const data = {url: this.urlTarget.innerHTML}
    if(this.hasTextValue) data.text = this.textValue

    navigator.share(data).catch(() => {})
  }
}
