import Base from "./base"

export default class extends Base {
  static targets = ['textarea', 'autocompleteRoot', 'visibleSearching', 'visibleManual']
  static values  = {
    noResults: String,
    searching: String,
    debounceMs: { type: Number, default: 200 },
    country: { type: String, default: 'UK' }
  }

  get firstInputElement() {
    return this.textareaTarget
  }

  shouldStartInSearchMode() {
    return this.textareaTarget.value.length === 0
  }

  showAddressFields() {
    super.showAddressFields()
    this.autocompleteInput.id = null
    this.textareaTarget.id = this._id
  }

  hideAddressFields() {
    this.textareaTarget.id = null
    this.textareaTarget.value = ''
    super.hideAddressFields()
  }

  countryValueChanged() {
    super.invalidateAddressLookup()
  }

  set completedAddress(value) {
    this.textareaTarget.value = value.formatted
  }
}
